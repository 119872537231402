<template>
	<div id="app">
		<div class="menu">
			<components-menu :menu="menu" :collapse="collapse"/>
		</div>
		<div class="app-content">
			<div class="app-header">
				<components-header :collapse="collapse" @toggleCollapse="toggleCollapse"/>
			</div>
			<div class="app-container">
				<div class="app-tags">
					<components-tags/>
				</div>
				<div class="content">
					<transition name="fade">
						<router-view v-if="is_dev" :key="route_key"></router-view>
						<keep-alive v-else>
							<router-view></router-view>
						</keep-alive>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ComponentsHeader from "@/components/layout/admin/Header.vue"
import ComponentsMenu from "@/components/layout/admin/Menu.vue"
import ComponentsTags from "@/components/layout/admin/Tags.vue"
import {auth} from "@/api/admin";
import {site_config} from "@/config";
import {isDev} from "@/common/other";
import "@/plugins/admin"

export default {
	components: {ComponentsHeader, ComponentsTags, ComponentsMenu},
	data() {
		return {
			collapse: false,
			menu: [
				{
					title: "控制台", icon: 'el-icon-odometer',
					children: [
						{title: "数据看板", path: '/admin/'},
						{title: "数据大屏", path: '/admin/dashboard/chart'},
					]
				},
				{
					title: "工作管理", icon: 'el-icon-tickets',
					children: [
						{title: "任务清单", path: '/admin/work/task'},
						{title: "任务计划", path: '/admin/work/plan'},
						{title: "申请报废", path: '/admin/good/drop'},
					]
				},
				{
					title: "设备管理", icon: 'el-icon-tickets',
					children: [
						{title: "设备清单", path: '/admin/good/index'},
						{title: "设备分类", path: '/admin/good/category'},
					]
				},
				{
					title: "员工管理", icon: 'el-icon-tickets',
					children: [
						{title: "员工列表", path: '/admin/user'},
						{title: "用户反馈", icon: 'el-icon-tickets', path: '/admin/feedback'},
						{title: "部门管理", path: '/admin/department'},
					]
				},
				{title: "Banner管理", icon: 'el-icon-tickets', path: '/admin/banner'},
				{title: "文件管理", icon: 'el-icon-tickets', path: '/admin/file'},
				{title: "系统配置", icon: 'el-icon-tickets', path: '/admin/config'},
				{title: "管理员", icon: 'el-icon-user', path: '/admin/admin'},
			]
		}
	},
	computed: {
		route_key() {
			return this.$route.fullPath
		},
		meta() {
			return this.$route.meta;
		},
		is_dev() {
			return isDev();
		}
	},
	created() {
		document.title = `${site_config.title}`
		this.getAdminInfo();
	},
	methods: {
		isDev,
		toggleCollapse() {
			this.collapse = !this.collapse;
		},
		getAdminInfo() {
			auth.getLoginInfo().then((res) => {
				this.$store.commit('admin/setAdminInfo', res.admin)
			})
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/admin/css/reset.scss';
@import '@/assets/admin/css/ricktext.scss';
</style>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {transition: all .3s ease;}
.fade-enter-from, .fade-leave-to {opacity: 0;transition: all .3s ease;}
#app {position: relative;min-height: 100vh;display: flex;min-width: 1000px;
	.app-content {flex: 1;overflow: hidden;
		.app-tags {margin-bottom: 15px;}
		.app-container {padding: 15px;background: #efefef;height: calc(100vh - 56px);overflow: hidden;overflow-y: auto;
			.content {background: #fff;}
		}
	}
}
</style>
